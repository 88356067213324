import React from 'react'
import Button from '../Button'

const link = (name) => (name === '/index' ? '/' : `/${name}`)

const ButtonSelector = (props) => {
    // const input = props.linkTo;
    const data = props.data
    if (data.linkTo === 'Form') {
        return (
            <Button
                {...props}
                target="blank"
                variant="contained"
                disableElevation
                size="large"
                color="primary"
                href={`/${data.link}`}
            >
                {data.label}
            </Button>
        )
    } else if (data.pageexternalpdf && data.pageexternalpdf.externalLink) {
        return (
            <Button
                {...props}
                target="blank"
                variant="contained"
                disableElevation
                size="large"
                color="primary"
                href={data.pageexternalpdf.externalLink}
            >
                {data.label}
            </Button>
        )
    } else if (data.pageexternalpdf && data.pageexternalpdf.name) {
        if (data.pageexternalpdf.pathPrefix) {
            return (
                <Button
                    {...props}
                    variant="contained"
                    disableElevation
                    size="large"
                    color="primary"
                    to={link(
                        `${data.pageexternalpdf.pathPrefix}/${data.pageexternalpdf.name}`
                    )}
                >
                    {data.label}
                </Button>
            )
        }
        return (
            <Button
                {...props}
                variant="contained"
                disableElevation
                size="large"
                color="primary"
                to={link(data.pageexternalpdf.name)}
            >
                {data.label}
            </Button>
        )
    } else
        return (
            <Button
                {...props}
                variant="contained"
                disableElevation
                size="large"
                color="primary"
                to={link(data.anchorLink)}
            >
                {data.label}
            </Button>
        )
}

export default ButtonSelector
